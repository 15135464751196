// Dependencies
import React from 'react'
import { motion } from 'framer-motion'

class OutputDisplay extends React.Component {

	
	constructor(props) {
		super(props)
		if(!props.output) throw new Error('output is not defined')
	}


	render() {
		const { output, outputWatching } = this.props

		return <motion.div className='output'
			key={'output-id-item-' + output}
		>
			{
				Object.keys(output).map((key, index) => {
					// console.log(outputWatching, key, output[index], outputWatching == output[index].id)
					// console.log(key, index, output[key], output[index])
					return <motion.div key={'top-room-range-item-'+key}
						layoutId={'top-room-range-item-'+key}
					>
						<motion.h2 
							style={{ margin: 0 }}
							initial={{
								x: '100%',
								y: -100,
							}}
							animate={{
								x: 0,
								y: 0,
							}}
							transition={{ 
								duration: .35,
							}}
						>{output[key].type}:</motion.h2>
						<motion.div className='item' key={'room-range-item-'+key}
							animate={{
								opacity: 1,
								scale: 1,
								borderColor: outputWatching == key ? 'blue' : '#e1e1e1',
							}}
							transition={{ 
								duration: 2,
							}}
						>
							{
								output[key].cards.map((card, index2) => {
									return <motion.div className='itemConf'
											layoutId={'card-item-' + (card.cardNumber + card.cardSuit).toString().toLowerCase()}
											key={'card-out-item-' + (card.cardNumber + card.cardSuit).toString().toLowerCase()}
											data-layout-id={'card-item-' + (card.cardNumber + card.cardSuit).toString().toLowerCase()}
											style={{
												// width: `calc(${(conf.remainder / 7) * 100}% - 10px)`,
												// backgroundColor: `hsl(${(conf.id / 7) * 360}, 100%, 50%)`,
											}}
											transition={{ 
												duration: .5,
											}}
										>
											<span>{card.cardNumber + card.cardSuit}</span>
										</motion.div>
								})
							}
						</motion.div>
					</motion.div>
				})
			}
        </motion.div>
	}
}

export default OutputDisplay;