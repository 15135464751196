// Dependencies
import React from 'react'
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'

// Library
import { CardSolve } from './cards/main.js'

// Components
import MeetingCards from './components/meeting-cards'
import OutputDisplay from './components/output-display'
import CardList from './components/card-list'

class App extends React.Component {

	constructor(props) {
		super(props)
		this.showFile = this.showFile.bind(this)
		this.onStateChange = this.onStateChange.bind(this)
	}

	state = {
		fileContent: null,
		setWatchingTxt: null,
		setExplanTxt: null,
		crrCardWatch: [],
		cardMarked: [],
		cardsList: [],
		cardWatching: [],
		output: {},
		success: ""
	}

	onStateChange = (obj) => {
		console.log(obj)
		const { state, data } = obj
		this.setState({
			[state]: data,
		})
		
	}

	render() {
		const { 
			fileContent,
			setWatchingTxt,
			setExplanTxt,
			crrCardWatch,
			cardMarked,
			cardsList,
			cardWatching,
			output,
			success
		} = this.state

		return <div className="App">
			<AnimateSharedLayout mode="wait">
				<AnimatePresence key={'main'}>
					<h1 style={{textAlign: 'center'}} key={'watchTxt'}>{setWatchingTxt ?? "Waiting..."}</h1>
					<p style={{textAlign: 'center'}} key={'explainTxt'}>{setExplanTxt ?? "-"}</p>
					<motion.div className="success"
						key="success"
						style={{
							display: success !== "" ? "block" : "none"
						}}
					>
						<h1>Result</h1>
						<span>{success}</span>
					</motion.div>
					{ !fileContent &&
						<div className='chooseFile' key="chooseFile">
							<input id="inputChooseFile" type="file" onChange={(e) => this.showFile(e)} />
							<button onClick={()=>{
								document.getElementById('inputChooseFile').click()
							}}>Choose file</button>
						</div>
					}
					{ fileContent &&
						<div className='col-2' key="col-2">
							<div className='content-side' key="content-side-1">
								{/* <MeetingCards conferences={conferences} conferencesWatching={conferencesWatching}/> */}
								{Object.keys(output).length > 0 &&
									<OutputDisplay output={output}/>
								}
							</div>
							<div className='content-side' key="content-side-2" >
								
								{cardsList.length > 0 &&
									<CardList cardsList={cardsList} cardWatching={cardWatching} crrCardWatch={crrCardWatch} cardMarked={cardMarked}/>
								}
							</div>
						</div>
					}
				</AnimatePresence>
			</AnimateSharedLayout>
		</div>
	}

	showFile = async (e) => {
		e.preventDefault()
		const reader = new FileReader()
		reader.onload = async (e) => { 
			const text = (e.target.result)
			this.setState({ fileContent: text })
			CardSolve(text, this.onStateChange)
		};
		reader.readAsText(e.target.files[0])
	}
}

export default App;
