/* Classes */
import {Card} from './Card.js';

import { sleep } from '../main.js';

/**
 * Base Solver Class
 * This class stand as a base class for all solvers.
 */

export class Solver {
    constructor(cards, type) {
        // this.findPossibleWinning(cards, type);
    }

    async findPossibleWinning(cards, type) {
        this.type = type;
        if(Solver.callback) Solver.callback({ state: 'setWatchingTxt', data: "Watching for " + type });
        if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: null });
        if(Solver.callback) Solver.callback({ state: 'cardMarked', data: [] });
        if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: [] });
        await sleep(250);

        // Game Settings
        this.straightLength = 5; // This is length that will determine that card in the suit group are capable for straight or not.

        // Array to store a solved card.
        this.cards = [];

        // Parse each card in input as a card in hand.
        this.cardsInHand = cards.map(function(card) {
            return (typeof card === 'string') ? new Card(card) : card; // Don't convert if it's already a Card object.
        });

        // Sort cards in hand by rank.
        this.cardsInHand = this.cardsInHand.sort(Card.sort);

        // Then, parse each card to contained it in an array for solving.
        this.cardSuits = [];
        this.cardNumbers = [];

        let suitObj, numberObj, suitKey, numberKey;
        for (const card of this.cardsInHand) {
            // Build object if it's not having one.
            (suitObj = this.cardSuits)[suitKey = card.cardSuit] || (suitObj[suitKey] = []);
            (numberObj = this.cardNumbers)[numberKey = card.cardNumber] || (numberObj[numberKey] = []);

            // Push card to the object.
            this.cardSuits[card.cardSuit].push(card);
            this.cardNumbers[card.cardNumber].push(card);
        }

        this.cardNumbers.reverse();

        // Call a subclass solve method.
        this.possibleWinning = await this.solve();
        await sleep(500);
    }

    // Static function to call all subclasses' solver.
    static async solve(cards) {
        cards = cards || [];

        const solvers = [StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, TwoPair, Pair, HighCard];

        // Loop each solver
        for (const solver of solvers) {
            const result = new solver(cards);
            console.log(123)
            await result.init(cards);
            console.log(321)
            if (result.possibleWinning) {
                if(Solver.callback) Solver.callback({ state: 'cardWatching', data: result.cards });
                await sleep(500);
                return result;
            }
        }

        return false;
    }

    static callback = null;
}

/* Card Solver */

/**
 * StraightFlush Solver
 */

class StraightFlush extends Solver {
    constructor(cards) {
        super(cards, 'Straight Flush');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Straight Flush');
    }

    async solve() {
        let cards;
        let possibleStraightCards;

        // Check if there is a straight flush in hand. By looping each suit.
        for (const suit in this.cardSuits) {
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find " + this.straightLength + " card with suit " + suit });
            cards = this.cardSuits[suit];
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: cards });
            await sleep(250);

            // If cards in this suit is met the condition for straight, then save this set of cards.
            if (cards && cards.length >= this.straightLength) {
                possibleStraightCards = cards;
                break;
            }
        }

        // If there are any cards suit that maybe possible winning straight (cards in suit >= straightLength),
        // Then processing the determiner. By reuse a straight solver.
        if (possibleStraightCards) {
            // Tricking Straight function to check if there is a straight in hand.
            // But passing only a card that in the suit. (To see that there is a straight flush in hand.)
            const straightSolver = new Straight(possibleStraightCards);
            await straightSolver.init(possibleStraightCards);

            // If there is a straight in the possible cards, then this hand is a straight flush.
            if (straightSolver.possibleWinning) {
                this.cards = straightSolver.cards; // Set this.cards as a solved cards.
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
            }
        }

        return this.cards.length >= this.straightLength;
    }
}

/**
 * FourOfAKind Solver
 */
class FourOfAKind extends Solver {
    constructor(cards) {
        super(cards, 'Four of a Kind');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Four of a Kind');
    }

    async solve() {
        // Simple, Look in a number group that having length === 4 on a current hand.
        for (const number in this.cardNumbers) {
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find 4 cards with number " + number });
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardNumbers[number] });
            await sleep(250);
            if (this.cardNumbers[number].length === 4) {
                this.cards = this.cardNumbers[number];
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                break;
            }
        }

        return this.cards.length === 4;
    }
}

/**
 * FullHouse Solver
 */
class FullHouse extends Solver {
    constructor(cards) {
        super(cards, 'Full House');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Full House');
    }

    async solve() {
        // Look in a number group that having length === 3 on a current hand.
        for (const number in this.cardNumbers) {
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find 3 cards with number " + number });
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardNumbers[number] });
            await sleep(250);
            if (this.cardNumbers[number].length === 3) {
                this.cards = this.cardNumbers[number];
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                break;
            }
        }

        // If the FullHouse type of card set is founded
        if (this.cards.length === 3) {
            // Then, look for a pair of cards.
            for (const number in this.cardNumbers) {
                if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find 2 cards with number " + number });
                if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardNumbers[number] });
                await sleep(250);
                if (this.cardNumbers[number].length === 2) {
                    this.cards = this.cards.concat(this.cardNumbers[number]);
                    if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                    break;
                }
            }
        }

        return this.cards.length >= 5;
    }
}

/**
 * Flush Solver
 */
class Flush extends Solver {
    constructor(cards) {
        super(cards, 'Flush');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Flush');
    }

    async solve() {
        // Look for a suit group that having length >= this.straightLength and card rank should have unbroken sequence.
        for (const suit in this.cardSuits) {
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find " + this.straightLength + " cards with suit " + suit });
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardSuits[suit] });
            await sleep(250);
            if (this.cardSuits[suit].length >= this.straightLength) {
                this.cards = this.cardSuits[suit];
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                break;
            }
        }

        if (this.cards.length >= this.straightLength) {
            this.cards = this.cards.slice(0, this.straightLength);
            if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
        }

        // NOTE: we don't need to calculate for unbroken sequence here.
        // In-order to achieve this, We need to call a Straight Flush solver to check if there is a sequence in the cards.
        // If not, then this hand is not a straight flush. And Flush solver will solve this.
        return this.cards.length > this.straightLength;
    }
}

/**
 * Straight Solver
 */

class Straight extends Solver {
    constructor(cards) {
        super(cards, 'Straight');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Straight');
    }

    // Sub-solver method.
    async solve() {
        // We're going to loop each card, By ranking.
        let i = global.cardsVal.length;

        // Loop the rank or length down.
        for (; i > 0; i--) {
            // Memorize cards and gaps between cards for calculation.
            const cards = [];
            let gaps = 0;
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find Straight from cardsInHand "});
            for (const card of this.cardsInHand) {
                // if(Solver.callback) Solver.callback({ state: 'cardMarked', data: cards });
                // Skip If the card rank are more than i, which is current top card
                // We're only want to card that have a rank lower than i
                if (card.rank > i) {
                    continue;
                }

                // Get the last cards in the possible result array.
                const lastCard = cards[cards.length - 1];
                const rankDiff = lastCard ? lastCard.rank - card.rank : i - card.rank;

                //console.log('rankDiff:', rankDiff)

                // Nothing to compare, Maybe a first card of the array, Push a card into array anyway.
                if (!rankDiff) {
                    cards.push(card);
                    //console.log('pushing (1):', card);

                    continue;
                }

                // If the sum of gaps and rank difference and number of cards is more than straight length,
                // Then, break the loop.
                //console.log(this.straightLength, (gaps + rankDiff + cards.length))
                if (this.straightLength < (gaps + rankDiff + cards.length)) {
                    //console.log('break');
                    break;
                }

                // If the rank difference is more than 1, it means that there are gaps between cards.
                // So we're going to add the gaps to the gaps variable.
                if (rankDiff > 0) {
                    cards.push(card); // Push a card into array.
                    //console.log('pushing (2):', card);

                    gaps += rankDiff - 1;
                }

                if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: cards });
                await sleep(250);

                //console.log('cards:', cards);
            }

            // Set the result array if the new solved cards length are more than current result.
            // So when checking for straight condition, This will more likely have a potential to be straight.
            //console.log(cards);
            //console.log('finalized cards:', cards);
            if (cards.length > this.cards.length) {
                this.cards = cards.slice(0, this.straightLength);
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
            }

            if (this.straightLength - this.cards.length <= 0) {
                break;
            }
        }

        // Return if the cards length are more than straight length.
        //console.log(this.cards);
        return (this.cards.length >= this.straightLength);
    }
}

/**
 * ThreeOfAKind Solver
 */
class ThreeOfAKind extends Solver {
    constructor(cards) {
        super(cards, 'Three of a Kind');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Three of a Kind');
    }

    async solve() {
        // Simple, Look in a number group that having length === 3 on a current hand.
        for (const number in this.cardNumbers) {
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find 3 cards with number " + number });
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardNumbers[number] });
            await sleep(250);
            if (this.cardNumbers[number].length === 3) {
                this.cards = this.cardNumbers[number];
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                break;
            }
        }

        return this.cards.length === 3;
    }
}

/**
 * TwoPair Solver
 */
class TwoPair extends Solver {
    constructor(cards) {
        super(cards, 'Two Pair');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Two Pair');
    }

    async solve() {
        // Look for a pair of cards.
        for (const number in this.cardNumbers) {
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find first 2 cards with number " + number });
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardNumbers[number] });
            await sleep(250);
            if (this.cardNumbers[number].length === 2) {
                this.cards = this.cardNumbers[number];
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                break;
            }
        }

        // If the TwoPair type of card set is founded
        if (this.cards.length === 2) {
            // Then, look for a pair of cards.
            for (const number in this.cardNumbers) {
                if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find second 2 cards with number " + number });
                if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardNumbers[number] });
                await sleep(250);
                if (this.cardNumbers[number].length === 2 && this.cards[0].rank !== this.cardNumbers[number][0].rank) {
                    this.cards = this.cards.concat(this.cardNumbers[number]);
                    if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                    break;
                }
            }
        }

        return this.cards.length >= 4;
    }
}

/**
 * Pair Solver
 */
class Pair extends Solver {
    constructor(props) {
        super(props, 'Pair');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'Pair');
    }

    async solve() {
        // Look for a pair of cards.
        // Am tired commenting things now, Good luck on presentation - @holfz
        for (const number in this.cardNumbers) {
            if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Find 2 cards with number " + number });
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cardNumbers[number] });
            await sleep(250);
            if (this.cardNumbers[number].length === 2) {
                this.cards = this.cardNumbers[number];
                if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
                break;
            }
        }

        return this.cards.length === 2;
    }
}

/**
 * HighCard Solver
 */
class HighCard extends Solver {
    constructor(cards) {
        super(cards, 'High Card');
    }

    async init(cards) {
        await this.findPossibleWinning(cards, 'High Card');
    }

    async solve() {
        // Just return the first card in the hand.
        if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: "Just pick one card from cardsInHand" });
        this.cards = [this.cardsInHand[0]];
        if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: this.cards });
        await sleep(250);
        if(Solver.callback) Solver.callback({ state: 'cardMarked', data: this.cards });
        return true;
    }
}
