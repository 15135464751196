/* Dependencies */
// const { readFile, writeFile } = require('fs').promises;

/* Classes */
import {Card} from './Components/Card.js';

/* Solver */
import {Solver} from './Components/Solver.js';


/* Main */
export async function CardSolve(fileContent, callback){
    // Read input files
    const inputs = fileContent;

    // Parse
    const input = inputs.split(',');
    callback({ state: 'cardsList', data: input });
    callback({ state: 'setWatchingTxt', data: "Reading..." });
    await sleep(1000);
    // Create object to store output
    const output = {};

    // Loop for 3 sets of cards
    console.log(input, output);
    for (let i = 3; i > 0; i--) {
        callback({ state: 'cardsList', data: input });
        // If not have remaining cards anymore, break.
        if (input.length === 0) break;

        // Solve a remaining cards
        Solver.callback = callback;
        const result = await Solver.solve(input, 3);
        

        // Remove the cards from the input
        for (const card of result.cards) {
            input.splice(input.indexOf(`${card.cardNumber}${card.cardSuit}`), 1);
        }

        

        

        // Store the result
        output[i] = result;
        

        // Janky, but it works.
        // If it's the final set of cards, and there's still cards left, add it to the last set.
        // FIXME: Don't use in real world.
        if (i === 1 && input.length > 0) {
            if(Solver.callback) Solver.callback({ state: 'setWatchingTxt', data: "Filling high card to set" });
            await sleep(250);

            let cardList = input.map((card) => new Card(card));
            if(Solver.callback) Solver.callback({ state: 'crrCardWatch', data: cardList });
            await sleep(250);
            if(Solver.callback) Solver.callback({ state: 'cardMarked', data: cardList });
            await sleep(250);
            if(Solver.callback) Solver.callback({ state: 'cardWatching', data: cardList });
            await sleep(250);

            output[i].cards.push(...cardList);
            input.splice(0, input.length);
        }
        // console.log(input, output);
        callback({ state: 'output', data: output });
        await sleep(1000);
        // return;
    }

    if(Solver.callback) Solver.callback({ state: 'setExplanTxt', data: null });

    // Janky again, If the 2nd set is not having a length of 5, move the 1rd set to the 2nd set.
    // FIXME: Don't use in real world.
    if (output[2].cards.length < 5 && output[3].cards.length > 3) {
        if(Solver.callback) Solver.callback({ state: 'setWatchingTxt', data: "Moving high card to fill higher card set" });
        output[2].cards.push(...output[1].cards.splice(-(5 - output[2].cards.length)));
    }

    callback({ state: 'output', data: output });
    callback({ state: 'cardsList', data: input });
    callback({ state: 'Resulting...', data: "Reading" });
    await sleep(1000);
    if(Solver.callback) Solver.callback({ state: 'setWatchingTxt', data: "Completed" });

    // Parse Output Object
    let outputStr = "";
    for (const cardSet of Object.keys(output)) {
        outputStr += `${cardSet}:${output[cardSet].cards.map(card => `${card.cardNumber}${card.cardSuit}`).join(',')} (${output[cardSet].type})\n`;

        // Debug
        console.log(`Set ${cardSet} Solved: ${output[cardSet].cards.map(card => `${card.cardNumber}${card.cardSuit}`).join(', ')} | Type: ${output[cardSet].type}`);
    }


    // await writeFile('./output.txt', outputStr);
};

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}