/* Variable */
// Array to store card rank value, Based on number of a card.
global.cardsVal = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

/**
 * Card component.
 * This component represents each card in the solver hand.
 */

 export class Card {
    constructor(cardStr) {
        // Parse string input as a card
        // If it's number, use 2 start-end offset instead of 1.
        this.cardStr = cardStr;
        if (cardStr.substring(0, 2) === '10') {
            this.cardNumber = cardStr.substring(0, 2);
            this.cardSuit = cardStr.substring(2, 3);
        } else {
            this.cardNumber = cardStr.substring(0, 1);
            this.cardSuit = cardStr.substring(1, 2);
        }

        this.rank = global.cardsVal.indexOf(this.cardNumber);
    }

    static sort(a, b) {
        return (a.rank < b.rank) ? 1 : (a.rank > b.rank) ? -1 : 0;
    }
}