// Dependencies
import React from 'react'
import { motion } from 'framer-motion'

class CardList extends React.Component {

	
	constructor(props) {
		super(props)
		if(!props.cardsList) throw new Error('roomRange is not defined')
	}


	render() {
		const { cardsList, cardWatching, crrCardWatch, cardMarked } = this.props

		return <motion.div className='card-list'
			key={'card-list'}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{
				cardsList.map((t, index) => {
					return <motion.div className='item'
							layoutId={'card-item-' + t.toString().toLowerCase()}
							key={'card-item-' + t.toString().toLowerCase() + index}
							data-layout-id={'card-item-' + t.toString().toLowerCase()}
							style={{
								backgroundColor: cardWatching && cardWatching.find((card) => card && card.cardStr === t)
									?'green' :
									(cardMarked && cardMarked.find((card) => card && card.cardStr === t) ? 'blue' : 
										(((crrCardWatch && crrCardWatch.find((card) => card && card.cardStr === t))
											? 'yellow' :'white'))),
								// width: `calc(${(t / 7) * 100}% - 10px)`,
								// backgroundColor: `hsl(${((index + 1) / 7) * 360}, 100%, 50%)`,
							}}
							transition={{ 
								duration: .5,
							}}
						>
							<span>{t}</span>
						</motion.div>
				})
			}
        </motion.div>
	}
}

export default CardList;